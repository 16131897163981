<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <select v-bind:id="component_id" v-bind:name="component_meta.variable" class="form-control"
    @change="load_widget_property_fields($event.target.value)" v-bind:value="selectedOption">
    <template v-for="(meta, widget_meta_name) in widget_select" :key="widget_meta_name">
      <option v-bind:value="widget_meta_name">
        {{ meta.title }}
        <template v-if="widget_meta_name != ''">[{{ widget_meta_name }}]</template>
      </option>
    </template>
  </select>
  <br />
  <div class="row">
    <div v-for="field_meta in selected_meta" :key="field_meta.variable" v-bind:class="[
      'form-group',
      'col-' + field_meta.component.properties.col_width.mobile,
      'col-sm-' + field_meta.component.properties.col_width.pad,
      'col-md-' + field_meta.component.properties.col_width.pad,
      'col-lg-' + field_meta.component.properties.col_width.desktop
    ]">
      <component 
        v-bind:is="field_meta._component" 
        v-bind:key="field_meta.variable"
        v-bind:field="field_meta"
        v-bind:value="component_value.properties[field_meta.variable]" 
        @input="update_input_value(field_meta, $event)">
      </component>
    </div>
  </div>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {
    // 載入組件Meta清單給下拉元件
    let api_url = this.$microlink.base_api_url + this.field.component.properties.list_widget_metadatas;
    let widget_select = this.$microlink.api(api_url);
    widget_select = { '': { 'title': '請選擇', 'properties': '[]' }, ...widget_select };
    
    // 初始化
    let localValue = this.value;
    if (localValue == undefined) {
      localValue = { 'type': '', 'properties': {} };
    }

    return {
      component_id: 'widget-select-' + Math.random().toString().substring(2),
      component_meta: this.field,
      component_value: localValue,
      widget_select: widget_select,
      selected_meta: {},
      selectedOption: localValue.type
    };
  },
  mounted() {
    if (this.selectedOption != '' && this.selectedOption != undefined) {
      this.load_widget_property_fields(this.selectedOption);
    }
  },
  methods: {
    load_widget_property_fields: function (widget_meta_name) {

      this.selectedOption = widget_meta_name;
      this.selected_meta = this.widget_select[widget_meta_name].fields;
      this.component_value.type = widget_meta_name;

      if (this.component_value.properties == undefined) {
        this.component_value.properties = {};
      }


      for (let key in this.selected_meta) {
        // 初始化 data
        if (this.component_value.properties[this.selected_meta[key].variable] == undefined) {
          this.component_value.properties[this.selected_meta[key].variable] = '';
        }

        // 取得
        this.selected_meta[key]._component
          = this.$microlink.components[this.selected_meta[key].component.type].component;
      }

    },
    update_input_value: function (meta, $event) {
      this.component_value.properties[meta.variable] = ($event.temp == undefined) ? $event.target.value : $event.temp;
      $event.temp = this.component_value;
      this.$emit('input', $event);
    }
  }
}
</script>

<style></style>